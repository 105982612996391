import _ from 'lodash';
import moment from 'moment';
import { getAllCustomerData } from '../../services/Redux/Reducer/LoginSlice';
import localStorageCall from '../Methods/localstorage.hook';
import { storeAssigned, _dateFormatChange } from '../Methods/normalMethods';
import { getCurrencyCode } from '../Methods/normalMethods';

// function DATES_VALUES(data, e = false, s = false, d = false) {
//     return ({
//         entryDate: e ? data?.entryDate : moment.utc().format(),
//         signUpDate: s ? data?.signUpDate : moment.utc().format(),
//         distributorDate: d ? data?.distributorDate : moment.utc().format(),
//     });
// }

function DATES_VALUES(data, e = false, s = false, d = false) {
    var date = new Date();
    return ({
        entryDate: (e && data?.entryDate != null) ? data?.entryDate : moment(date).utc().format(),
        signUpDate: (s && data?.signUpDate != null) ? data?.signUpDate : moment(date).utc().format(),
        distributorDate: d ? data?.distributorDate : moment(date).utc().format(),
    });
}

export const _signUpPayload = (data, customerType = 1, section, commingFrom) => {
    let DATES = {};
    let CUSTOMER_TYPE = localStorageCall()?.getItem('customerTypeDescr');
    if (commingFrom === "updated") {
        console.log('1111111111111111111', "CUSTOMER_TYPE?.type", CUSTOMER_TYPE?.type, "customerType", customerType)
        if ((CUSTOMER_TYPE?.type === 1 && customerType === 2)) {
            DATES = DATES_VALUES(data, true, true, true);

        } else if (((CUSTOMER_TYPE?.type === 1 && customerType === 3) || (CUSTOMER_TYPE?.type === 1 && customerType === 4))) {
            DATES = DATES_VALUES(data, true, true, false);

        }
        else if (CUSTOMER_TYPE?.type === 2 && customerType === 3) {
            DATES = DATES_VALUES(data, true, true, false);

        }
        else if ((CUSTOMER_TYPE?.type === 1 && customerType === 1) || (CUSTOMER_TYPE?.type === 2 && customerType === 2) || (CUSTOMER_TYPE?.type === 3 && customerType === 3)) {
            DATES = DATES_VALUES(data, true, true, true);
        }
        else {
            DATES = DATES_VALUES(data, true, true, false);
        }
    }
    else {
        console.log('22222222222222222', "CUSTOMER_TYPE?.type", CUSTOMER_TYPE?.type, "customerType", customerType)
        if (customerType === 3 || customerType === 4) {
            DATES = DATES_VALUES(data, false, false, false);
        } else {
            DATES = DATES_VALUES(data, false, false, true);
        }
    }
    const REFERRAL = localStorageCall().getItem('refferal_link');

    let WEB = { "webAlias": (section === "ambassador" || section === 'member') ? data?.webAlias : '' };
    let Enrollment = REFERRAL?.id ? { "enrollerId": REFERRAL?.id } : {};
    const extractPhoneNumber = (phoneNumber) => {
        return phoneNumber?.replace(/[\s~`!@#$%^&*(){}\[\];:"'<,.>?\/\\|_+=-]/g, '');
    }

    // console.table("data===================",data);

    console.log("data===================", JSON.stringify(data, null, 2));

    return ({
        ...DATES,
        "customerType": customerType,
        "customerSubType": 1,
        "customerStatusType": 1,
        "customerSubStatusType": 1,
        "firstName": data?.firstName || data?.email,
        "lastName": data?.lastName || data?.email,
        "middleName": "",
        "companyName": data?.company_name || "",
        "fullName": (data?.firstName && data?.lastName) ? (data?.firstName + ' ' + data?.lastName) : "",
        "suffix": "",
        "payToName": "",
        "genderType": 1,
        "userName": data?.email,
        "userPassword": data?.password,
        "userCanLogIn": true,
        "SmsChecked": true,
        "currencyCode": getCurrencyCode(_.upperCase(data?.country || data?.shipping_country || storeAssigned())),
        "countryCode": data?.country || storeAssigned().toUpperCase(),
        "membershipRenewalDate": data?.membershipRenewalDate || null,
        "birthDate": null,
        "phoneNumbers": {
            "cellPhone": extractPhoneNumber(data?.phone) || extractPhoneNumber(data?.shipping_phone) || '',
            "homePhone": extractPhoneNumber(data?.phone) || extractPhoneNumber(data?.shipping_phone) || '',
            "workPhone": extractPhoneNumber(data?.phone) || extractPhoneNumber(data?.shipping_phone) || '',
            "otherPhone1": "",
            "otherPhone2": ""
        },
        "emailAddress": data?.email || data?.shipping_email || "",
        "emailSubscribed": false,
        "emailSubscribedIp": "",
        "emailSubscribedDate": String(moment.utc().format()),
        "emailUnsubscribedDate": String(moment.utc().format()),
        "email2Address": "",
        "email2Subscribed": false,
        "email2SubscribedIp": "",
        "email2SubscribedDate": String(moment.utc().format()),
        "email2UnsubscribedDate": String(moment.utc().format()),
        "mailingAddress": {
            "address1": data?.address || data?.shipping_street || "",
            "address2": data?.address_2 || data?.shipping_street2 || "",
            "address3": "",
            "city": data?.city || data?.shipping_city || "",
            "regionProvState": data?.state || data?.shipping_state || "",
            "postalCode": data?.zipcode || data?.shipping_zipcode || "",
            "countryCode": data?.country || data?.shipping_country || ""
        },
        "shippingAddress": {
            "address1": data?.address || data?.shipping_street || "",
            "address2": data?.address_2 || data?.shipping_street2 || "",
            "address3": "",
            "city": data?.city || data?.shipping_city || "",
            "regionProvState": data?.state || data?.shipping_state || "",
            "postalCode": data?.zipcode || data?.shipping_zipcode || "",
            "countryCode": data?.country || data?.shipping_country || ""
        },
        "billingAddress": {
            "address1": data?.address || data?.shipping_street || "",
            "address2": data?.address_2 || data?.shipping_street2 || "",
            "address3": "",
            "city": data?.city || data?.shipping_city || "",
            "regionProvState": data?.state || data?.shipping_state || "",
            "postalCode": data?.zipcode || data?.shipping_zipcode || "",
            "countryCode": data?.country || data?.shipping_country || ""
        },
        "otherAddress1": {
            "address1": "",
            "address2": "",
            "address3": "",
            "city": "",
            "regionProvState": "",
            "postalCode": "",
            "countryCode": ""
        },
        "otherAddress2": {
            "address1": "",
            "address2": "",
            "address3": "",
            "city": "",
            "regionProvState": "",
            "postalCode": "",
            "countryCode": ""
        },
        "profileLink": "",
        "enrollerId": REFERRAL?.id || 2,

        ...WEB,
        // ...Enrollment,
        "enrollerBusinessCenterId": 0,
        "referral_id": data?.refferal || 1,
        "publicProfile": {
            "firstName": data?.firstName || "Test",
            "lastName": data?.lastName || "CommandConcepts",
            "fullName": (data?.firstName && data?.lastName) ? (data?.firstName + ' ' + data?.lastName) : "CommandConcepts",
            "phone": `${extractPhoneNumber(data?.phone) || extractPhoneNumber(data?.shipping_phone) || ''}`,
            "email": data?.email,
            "city": data?.city || data?.shipping_city || "",
            "regionProvState": data?.state || data?.shipping_state || "",
            "countryCode": data?.country || data?.shipping_country || ""
        },
        "otherFields": {
            "field11": data?.brand || "",
            "field12": data?.category || "",
            "field13": JSON.stringify(data?.checkValue) || "",
            "field14": data?.shirtsize || "",
            "field15": data?.selectedLanguage || "",
        },
    })
};
export function _storeCustomerTypes(dispatch, token, searchParams, callback) {
    dispatch(getAllCustomerData((data) => {
        if (data?.customerType === 4) {
            localStorageCall().setItem('customerTypeDescr', JSON.stringify({ type: data?.customerType, role: 'ambassador', customerType: data?.customerTypeDescr, priceType: 3 }));
        } else if (data?.customerType === 3) {
            localStorageCall().setItem('customerTypeDescr', JSON.stringify({ type: data?.customerType, role: 'member', customerType: data?.customerTypeDescr, priceType: 2 }));
        } else if (data?.customerType === 2) {
            localStorageCall().setItem('customerTypeDescr', JSON.stringify({ type: data?.customerType, role: 'VIP', customerType: data?.customerTypeDescr, priceType: 1 }));
        } else {
            localStorageCall().setItem('customerTypeDescr', JSON.stringify({ type: data?.customerType, role: 'lead', customerType: data?.customerTypeDescr, priceType: 1 }));
        }
        if (callback) {
            callback();
        }
        // localStorageCall().setItem('Dates', JSON.stringify({ distributorDate: data?.distributorDate, signUpDate: data?.signUpDate, entryDate: data?.entryDate }))
    }, token))
}

export function loopFunction(paymentFormHtml, callBackFunction, loaderFunction) {
    let tokenId = localStorage.getItem('order_token') ? localStorage.getItem('order_token') : "";
    let orderId = localStorage.getItem('orderId') ? localStorage.getItem('orderId') : "";
    let preAuthOrderId = localStorage.getItem('preAuthOrderId') ? localStorage.getItem('preAuthOrderId') : '';
    let errorPayment = localStorage.getItem('error-payment') ? localStorage.getItem('error-payment') : "";
    if (tokenId || orderId || preAuthOrderId) {
        if (errorPayment) {
            localStorageCall().removeItem('error-payment');
        }
        console.log(tokenId, orderId, preAuthOrderId, "tokenId,orderId,preAuthOrderId");
        callBackFunction({ tokenId, orderId, preAuthOrderId });
        localStorage.removeItem('order_token');
        localStorage.removeItem('orderId');
        localStorage.removeItem('preAuthOrderId');
    } else if (errorPayment) {
        if (loaderFunction) {
            loaderFunction({ errorPayment });
        }
        localStorage.removeItem('error-payment');
        // _scriptFunctionCall(paymentFormHtml, callBackFunction)
    } else {
        setTimeout(() => {
            console.log('dddddddddddddddddddddd')
            loopFunction(paymentFormHtml, callBackFunction, loaderFunction);
        }, 2000);
    }
}

export function _scriptFunctionCall(paymentFormHtml, callBackFunction, data, section = 'normal') {
    let i = 0;
    if (i < 1) {
        try {
            localStorage.removeItem('order_token');
            localStorage.removeItem('orderId');
            localStorage.removeItem('preAuthOrderId')
            i++;
            console.log("data in script function", data);
            // window.submitHandle(data);
            if (section === 'normal') {
                window.submitHandleView.createorder(data)
            } else if (section === 'autoship_edit') {
                window.submitHandleView.savepayment(data)
            }
            callBackFunction();
            // loopFunction(paymentFormHtml, callBackFunction);
        } catch (e) {
            console.log(e)
        }
    }


}

export const USERDATA_PAYLOAD = {
    shipping_urname: "", shipping_lastname: "", shipping_email: "", shipping_phone: "", shipping_delivery: '',
    shipping_street: "", shipping_street2: "", shipping_city: "", shipping_zipcode: "", shipping_country: "US", shipping_state: "",
    password: '', confirm_password: '', webAlias: "", select_language: '', search_enroller: '', taxType: '', taxId: ''
};

export const QuantityComponent = ({ row, _minCartQuantityIncDec }) => {
    return (
        <button type='button' className='btn-submit_n '>
            <div className="flex items-center gap-[10px] text-black lg:text-sm xl:text-lg !leading-3 border border-clr px-2">
                <span
                    className="cursor-pointer p-[2px]"
                    onClick={() => _minCartQuantityIncDec(row?.itemId, "sub")}
                >
                    -
                </span>
                <span className='bg-white px-[5px] flex justify-center items-center min-w-[32px] leading-[24px] pt-[3px]'>{row?.quantity}</span>
                <span
                    className="cursor-pointer p-[2px]"
                    onClick={() => _minCartQuantityIncDec(row?.itemId, "add")}
                >
                    +
                </span>
            </div>
        </button>
    )
}
export const PaymentOptionIDData = {
    description: "Card on File",
    notes: null,
    otherField1: null,
    otherField2: null,
    otherField3: null,
    paymentOptionActionConfig: null,
    paymentOptionId: 0,
    paymentOptionType: 0,
    paymentOptionTypeDescription: "Redirect",
    recurringSupported: true,
    secondaryRequestRequired: true,
    title: "Card on File"
}